import * as React from "react";
import Layout from "@layout";
import Header from "@layout/header/header-one";
import Footer from "@layout/footer/footer-one";
import Banner from "@components/banners/Ai";
import ResourceSection from '@containers/internal-resources'


const ResourcePage = ({ pageContext, location }) => (
	<Layout location={location}>
		<Header />
		<Banner
			pageContext={pageContext}
			location={location}
			title="Resources for Internal Use"
		/>
		<main className="site-wrapper-reveal">
			<ResourceSection />
		</main>
		<Footer />
	</Layout>
)

export default ResourcePage
