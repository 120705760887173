import React from "react"
import { Container, Row, Col } from "@ui/wrapper"
import Heading from "@ui/heading"
import Tabs, {
  TabHeader,
  NavItem,
  TabContent,
  TabPane,
} from "@ui/tabs/layout-one"
import { SectionWrap } from "./style"
import imageOne from "@data/images/HydroPhonics-cropped.png"
import { StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import AccordionWrap from "@ui/accordion"
import SectionTitle from "@ui/section-title"
import PopupVideo from "@ui/popup-video/layout-two"
import Line from "@ui/divider/line"
import List, { ListItem } from "@ui/list"

const textStyle = {
  color: "#6d6fb0",
  display: "unset",
}
const ResourceSection = ({
  accordionStyle,
  sectionTitleStyle,
  ListThreeStyle,
}) => {
  const getresourcedata = useStaticQuery(graphql`
    query resourcesdata {
      services: resourcesJson(id: { eq: "Services" }) {
        servicesitems {
          category
          description
          link
        }
      }
      resources: resourcesJson(id: { eq: "Resources" }) {
        resourcesitems {
          category
          description
          link
        }
      }
      univ: resourcesJson(id: { eq: "univ" }) {
        univitems {
          category
          description
          link
        }
      }
      tools: resourcesJson(id: { eq: "tools" }) {
        toolsitems {
          category
          description
          link
        }
      }
    }
  `)
  const {
    services: { servicesitems: services },
    resources: { resourcesitems: resources },
    univ: { univitems: univ },
    tools: { toolsitems: tools },
  } = getresourcedata
  const services_cate_list = services.map(service => {
    return service.category
  })
  const services_cates = [...new Set(services_cate_list.sort())]
  const resources_cate_list = resources.map(resource => {
    return resource.category
  })
  const resources_cates = [...new Set(resources_cate_list.sort())]
  const univ_cate_list = univ.map(uni => {
    return uni.category
  })
  const univ_cates = [...new Set(univ_cate_list.sort())]
  const tools_cate_list = tools.map(tool => {
    return tool.category
  })
  const tools_cates = [...new Set(tools_cate_list.sort())]

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Tabs defaultActiveKey="our-mission-tab">
              <TabHeader>
                <NavItem eventKey="our-mission-tab">Services</NavItem>
                <NavItem eventKey="our-services-tab">Resources</NavItem>
                <NavItem eventKey="partners-tab">Univ. Resources</NavItem>
                <NavItem eventKey="our-awards-tab">Tools</NavItem>
              </TabHeader>
              <TabContent pt="50px">
                <TabPane eventKey="our-mission-tab">
                  <Row>
                    <Col lg={12}>
                      {services_cates.map(services_cate => {
                        return (
                          <Row>
                            <Col lg={12}>
                              <Heading as="h6" mb="15px">
                                {services_cate}
                              </Heading>
                              <List {...ListThreeStyle}>
                                {services.map(service => {
                                  if (service.category === services_cate) {
                                    return (
                                      <font size="2">
                                        <ListItem>
                                          <a
                                            display="inline"
                                            href={service.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={textStyle}
                                          >
                                            {service.description}
                                          </a>
                                        </ListItem>
                                      </font>
                                    )
                                  }
                                })}
                              </List>
                              <Line mt="40px" mb="40px" borderStyle="dashed" />
                            </Col>
                          </Row>
                        )
                      })}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane eventKey="our-services-tab">
                <Row>
                    <Col lg={12}>
                      {resources_cates.map(resources_cate => {
                        return (
                          <Row>
                            <Col lg={12}>
                              <Heading as="h6" mb="15px">
                                {resources_cate}
                              </Heading>
                              <List {...ListThreeStyle}>
                                {resources.map(resource => {
                                  if (resource.category === resources_cate) {
                                    return (
                                      <font size="2">
                                        <ListItem>
                                          <a
                                            display="inline"
                                            href={resource.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={textStyle}
                                          >
                                            {resource.description}
                                          </a>
                                        </ListItem>
                                      </font>
                                    )
                                  }
                                })}
                              </List>
                              <Line mt="40px" mb="40px" borderStyle="dashed" />
                            </Col>
                          </Row>
                        )
                      })}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane eventKey="partners-tab">
                <Row>
                    <Col lg={12}>
                      {univ_cates.map(univ_cate => {
                        return (
                          <Row>
                            <Col lg={12}>
                              <Heading as="h6" mb="15px">
                                {univ_cate}
                              </Heading>
                              <List {...ListThreeStyle}>
                                {univ.map(uni => {
                                  if (uni.category === univ_cate) {
                                    return (
                                      <font size="2">
                                        <ListItem>
                                          <a
                                            display="inline"
                                            href={uni.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={textStyle}
                                          >
                                            {uni.description}
                                          </a>
                                        </ListItem>
                                      </font>
                                    )
                                  }
                                })}
                              </List>
                              <Line mt="40px" mb="40px" borderStyle="dashed" />
                            </Col>
                          </Row>
                        )
                      })}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane eventKey="our-awards-tab">
                <Row>
                    <Col lg={12}>
                      {tools_cates.map(tools_cate => {
                        return (
                          <Row>
                            <Col lg={12}>
                              <Heading as="h6" mb="15px">
                                {tools_cate}
                              </Heading>
                              <List {...ListThreeStyle}>
                                {tools.map(tool => {
                                  if (tool.category === tools_cate) {
                                    return (
                                      <font size="2">
                                        <ListItem>
                                          <a
                                            display="inline"
                                            href={tool.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={textStyle}
                                          >
                                            {tool.description}
                                          </a>
                                        </ListItem>
                                      </font>
                                    )
                                  }
                                })}
                              </List>
                              <Line mt="40px" mb="40px" borderStyle="dashed" />
                            </Col>
                          </Row>
                        )
                      })}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

ResourceSection.defaultProps = {
  accordionStyle: {
    responsive: {
      medium: {
        mb: "50px",
      },
    },
  },
  ListThreeStyle: {
    layout: "circle",
  },
  sectionTitleStyle: {
    mb: "30px",
    responsive: {
      small: {
        mb: "20px",
      },
    },
  },
}

export default ResourceSection
